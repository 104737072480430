export const COMMUNITY_LOGIN_QUERY = `{
    currentUser {
        id
        organization {
            id
            slug
            name
            logoUrl
            timezone
        }
    }
}
`
